import { useState } from 'react';
import { Grid, Typography, TextField, InputAdornment, Divider, Switch, Collapse } from '@mui/material';
import { FormControl, InputLabel, MenuItem, Select } from '@mui/material';
import LandscapeIcon from '@mui/icons-material/Landscape';
import FenceIcon from '@mui/icons-material/Fence';
import GrassIcon from '@mui/icons-material/Grass';
import PetsIcon from '@mui/icons-material/Pets';
import TransgenderIcon from '@mui/icons-material/Transgender';
import ScaleIcon from '@mui/icons-material/Scale';
import MonitorHeartIcon from '@mui/icons-material/MonitorHeart';
import YardIcon from '@mui/icons-material/Yard';
import { GiGrass } from 'react-icons/gi';
import { GiWheat } from 'react-icons/gi';
import { GiCow } from 'react-icons/gi';
import CropHeadInput from './CropHeadInput';
import { breeds, cropTypes, feedSupplements, healthStatuses } from '../shared/InputModels';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';

function FarmInputForm({ farm, updateFarm }) {
  const [showForage, setShowForage] = useState(farm.currentSimulationRecord.forageData.grasslandArea > 0);
  const [showCrop, setShowCrop] = useState(farm.currentSimulationRecord.cropData.cropArea > 0);
  const [showHerd, setShowHerd] = useState(farm.currentSimulationRecord.herdProperties.herdSize > 0);

  const handleToggle = (section) => {
    if (section === 'forage') {
      if (showForage) {
        updateFarm(farm.id, { ...farm, currentSimulationRecord: { ...farm.currentSimulationRecord, forageData: { ...farm.currentSimulationRecord.forageData, grasslandArea: 0 } } });
      }
      setShowForage(!showForage)
    } else if (section === 'crop') {
      if (showCrop) {
        updateFarm(farm.id, { ...farm, currentSimulationRecord: { ...farm.currentSimulationRecord, cropData: { ...farm.currentSimulationRecord.cropData, cropArea: 0 } } });
      }
      setShowCrop(!showCrop)
    } else if (section === 'herd') {
      if (showHerd) {
        updateFarm(farm.id, { ...farm, currentSimulationRecord: { ...farm.currentSimulationRecord, herdProperties: { ...farm.currentSimulationRecord.herdProperties, herdSize: 0 } } });
      }
      setShowHerd(!showHerd)
    }
  };

  return (
    <>
      <Grid item xs={12}>
        <Typography variant="h6" sx={{ mb: 2, display: 'flex', alignItems: 'center' }}>
          <GiGrass style={{ marginRight: '8px' }} />
          <div className='text primary bold xlarge'>Forage</div>
          <Switch
            checked={showForage}
            onChange={() => handleToggle('forage')}
            inputProps={{ 'aria-label': 'Forage Toggle' }}
          />
        </Typography>

        <Collapse in={showForage} timeout="auto">
          <Grid container spacing={2}>
            <Grid item xs={4}>
              <TextField
                label="Grassland Area"
                variant="outlined"
                value={farm.currentSimulationRecord.forageData.grasslandArea}
                onChange={(e) => updateFarm(farm.id, {
                  ...farm,
                  currentSimulationRecord: { ...farm.currentSimulationRecord, forageData: { ...farm.currentSimulationRecord.forageData, grasslandArea: Number(e.target.value) } }
                })}
                type="number"
                InputProps={{ endAdornment: <InputAdornment position="end"><LandscapeIcon />ha</InputAdornment> }}
                fullWidth
              />
            </Grid>
            <Grid item xs={4}>
              <TextField
                label="Grass Height"
                variant="outlined"
                value={farm.currentSimulationRecord.forageData.grassHeight}
                onChange={(e) => updateFarm(farm.id, {
                  ...farm,
                  currentSimulationRecord: { ...farm.currentSimulationRecord, forageData: { ...farm.currentSimulationRecord.forageData, grassHeight: Number(e.target.value) } }
                })}
                type="number"
                InputProps={{ endAdornment: <InputAdornment position="end"><GrassIcon />Cm</InputAdornment> }}
                fullWidth
              />
            </Grid>
          </Grid>
        </Collapse>
      </Grid>

      <Divider className='divider primary small' />



      <Grid item xs={12}>
        <Typography variant="h6" sx={{ mb: 2, display: 'flex', alignItems: 'center' }}>
          <GiWheat style={{ marginRight: '8px' }} />
          <div className='text primary bold xlarge'>Crop</div>
          <Switch
            checked={showCrop}
            onChange={() => handleToggle('crop')}
            inputProps={{ 'aria-label': 'Crop Toggle' }}
          />
        </Typography>

        <Collapse in={showCrop} timeout="auto">
          <Grid container spacing={2}>
            <Grid item xs={6}>
              <TextField
                label="Crop Area"
                variant="outlined"
                value={farm.currentSimulationRecord.cropData.cropArea}
                onChange={(e) => updateFarm(farm.id, {
                  ...farm,
                  currentSimulationRecord: { ...farm.currentSimulationRecord, cropData: { ...farm.currentSimulationRecord.cropData, cropArea: Number(e.target.value) } }
                })}
                type="number"
                InputProps={{ endAdornment: <InputAdornment position="end"><FenceIcon />ha</InputAdornment> }}
                fullWidth
              />
            </Grid>

            <Grid item xs={6}>
              <FormControl fullWidth variant="outlined">
                <InputLabel>Crop Type</InputLabel>
                <Select
                  IconComponent={GrassIcon}
                  value={farm.currentSimulationRecord.cropData.cropType}
                  onChange={(e) => updateFarm(farm.id, {
                    ...farm,
                    currentSimulationRecord: { ...farm.currentSimulationRecord, cropData: { ...farm.currentSimulationRecord.cropData, cropType: e.target.value } }
                  })}
                  label="Crop Type"
                >
                  {cropTypes.map((type) => (
                    <MenuItem key={type} value={type}>
                      {type}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
          </Grid>

          <CropHeadInput index={farm.id} farm={farm} updateFarm={updateFarm} />

        </Collapse>
      </Grid>

      <Divider className='divider primary small' />


      <Grid item xs={12}>
        <Typography variant="h6" sx={{ mb: 2, display: 'flex', alignItems: 'center' }}>
          <GiCow style={{ marginRight: '8px' }} />
          <div className='text primary bold xlarge'>Herd</div>
          <Switch
            checked={showHerd}
            onChange={() => handleToggle('herd')}
            inputProps={{ 'aria-label': 'Herd Toggle' }}
          />
        </Typography>

        <Collapse in={showHerd} timeout="auto">
          <Grid container spacing={2}>
            <Grid item xs={4} >
              <TextField
                label="Herd Size"
                variant="outlined"
                value={farm.currentSimulationRecord.herdProperties.herdSize}
                onChange={(e) => updateFarm(farm.id, {
                  ...farm,
                  currentSimulationRecord: { ...farm.currentSimulationRecord, herdProperties: { ...farm.currentSimulationRecord.herdProperties, herdSize: Number(e.target.value) } }
                })}
                type="number"
                InputProps={{ endAdornment: <InputAdornment position="end"><PetsIcon /></InputAdornment> }}
                fullWidth
              />
            </Grid>
            <Grid item xs={4} >
              <FormControl fullWidth variant="outlined">
                <InputLabel>Breed</InputLabel>
                <Select
                  IconComponent={TransgenderIcon}
                  value={farm.currentSimulationRecord.herdProperties.breed}
                  onChange={(e) => updateFarm(farm.id, {
                    ...farm,
                    currentSimulationRecord: { ...farm.currentSimulationRecord, herdProperties: { ...farm.currentSimulationRecord.herdProperties, breed: e.target.value } }
                  })}
                  label="Breed"
                >
                  {breeds.map((breed) => (
                    <MenuItem key={breed} value={breed}>
                      {breed}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={4} >
              <TextField
                label="Average Weight"
                variant="outlined"
                value={farm.currentSimulationRecord.herdProperties.weight}
                onChange={(e) => updateFarm(farm.id, {
                  ...farm,
                  currentSimulationRecord: { ...farm.currentSimulationRecord, herdProperties: { ...farm.currentSimulationRecord.herdProperties, weight: Number(e.target.value) } }
                })}
                type="number"
                InputProps={{ endAdornment: <InputAdornment position="end"><ScaleIcon />kg</InputAdornment> }}
                fullWidth
              />
            </Grid>

            <Grid item xs={4} >
              <TextField
                label="Average Age"
                variant="outlined"
                value={farm.currentSimulationRecord.herdProperties.age}
                onChange={(e) => updateFarm(farm.id, {
                  ...farm,
                  currentSimulationRecord: { ...farm.currentSimulationRecord, herdProperties: { ...farm.currentSimulationRecord.herdProperties, age: Number(e.target.value) } }
                })}
                type="number"
                InputProps={{ endAdornment: <InputAdornment position="end"><CalendarMonthIcon />Years</InputAdornment> }}
                fullWidth
              />
            </Grid>

            <Grid item xs={4} >
              <FormControl fullWidth variant="outlined">
                <InputLabel>Health Status</InputLabel>
                <Select
                  IconComponent={MonitorHeartIcon}
                  value={farm.currentSimulationRecord.herdProperties.healthStatus}
                  onChange={(e) => updateFarm(farm.id, {
                    ...farm,
                    currentSimulationRecord: { ...farm.currentSimulationRecord, herdProperties: { ...farm.currentSimulationRecord.herdProperties, healthStatus: e.target.value } }
                  })}
                  label="Health Status"
                >
                  {healthStatuses.map((status) => (
                    <MenuItem key={status} value={status}>
                      {status}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={4} >
              <FormControl fullWidth variant="outlined">
                <InputLabel>Feed Supplement</InputLabel>
                <Select
                  IconComponent={YardIcon}
                  value={farm.currentSimulationRecord.herdProperties.feedSupplement}
                  onChange={(e) => updateFarm(farm.id, {
                    ...farm,
                    currentSimulationRecord: { ...farm.currentSimulationRecord, herdProperties: { ...farm.currentSimulationRecord.herdProperties, feedSupplement: e.target.value } }
                  })}
                  label="Feed Supplement"
                >
                  {feedSupplements.map((supplement) => (
                    <MenuItem key={supplement} value={supplement}>
                      {supplement}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
          </Grid>
        </Collapse>
      </Grid>
    </>
  );
}

export default FarmInputForm;
