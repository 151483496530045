import React, { useContext, useState } from 'react';
import { Grid, TextField, InputAdornment, IconButton, Typography, Dialog, Box, DialogContent, CircularProgress, useMediaQuery } from '@mui/material';
import axios from 'axios';
import { AlertContext } from './Alerter';

import ZoomInIcon from '@mui/icons-material/ZoomIn';


const CropHeadInput = ({ farm, updateFarm }) => {
    const [image, setImage] = useState(null)
    const [imagePreview, setImagePreview] = useState('')
    const [isLoading, setisLoading] = useState(false)
    const { setAlert } = useContext(AlertContext)
    const [isZoomed, setZoomed] = useState(false)
    const isMobile = useMediaQuery('(max-width:1024px)')

    const handleImageUpload = (event) => {
        const file = event.target.files[0]
        if (file) {
            setImage(file)
            setImagePreview(URL.createObjectURL(file))
            uploadImage(file)
        }
    };

    const uploadImage = async (file) => {
        setisLoading(true)
        const formData = new FormData()
        formData.append('crops', file)

        try {
            const response = await axios.post(process.env.REACT_APP_BACKEND_API + '/count-crops', formData, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                },
            });


            // set detected crop heads
            setImagePreview(response.data.image)
            updateFarm(farm.id, { ...farm, currentSimulationRecord: { ...farm.currentSimulationRecord, cropData: { ...farm.currentSimulationRecord.cropData, headsPerM2: response.data.detectedCropHeads } } })
            setAlert("Detected crop heads: " + response.data.detectedCropHeads)
        } catch (error) {
            setAlert("Failed to count crop heads, please enter it manually", false)
        } finally {
            setisLoading(false)
        }
    };

    return (
        <Grid container mt={3}>
            <Grid item xs={isMobile ? 8 : 4} mr={"auto"} ml={"auto"}>
                <Grid item xs={12} sx={{ position: "relative" }}>
                    <input
                        accept="image/*"
                        style={{ display: 'none' }}
                        id={"image-upload-" + farm.id}
                        type="file"
                        onChange={handleImageUpload}
                        disabled={isLoading}
                        capture="environment"
                    />
                    <label htmlFor={"image-upload-" + farm.id} style={{ display: "flex", justifyContent: "center" }}>
                        <Box
                            sx={{
                                border: '2px dashed grey',
                                padding: '10px',
                                textAlign: 'center',
                                cursor: isLoading ? 'not-allowed' : 'pointer',
                                borderRadius: '8px',
                                position: 'relative',
                                '&:hover': {
                                    backgroundColor: !isLoading && '#f0f0f0',
                                },
                                width: "100%",
                                borderWidth: isMobile ? "0.5rem" : "0.2rem"
                            }}
                        >
                            {imagePreview ? (
                                <img
                                    src={imagePreview}
                                    alt="Crop head preview"
                                    style={{ width: '100%', height: 'auto', borderRadius: '5px' }}
                                />
                            ) : (
                                <div className='text primary large pt-medium pb-medium'>Upload an image for automatic crop head counting</div>
                            )}

                            {isLoading && (
                                <Box
                                    sx={{
                                        position: 'absolute',
                                        top: '50%',
                                        left: '50%',
                                        transform: 'translate(-50%, -50%)',
                                        backgroundColor: 'rgba(255, 255, 255, 0.7)',
                                        borderRadius: '8px',
                                        width: '100%',
                                        height: '100%',
                                        display: 'flex',
                                        alignItems: 'center',
                                        justifyContent: 'center',
                                    }}
                                >
                                    <CircularProgress />
                                </Box>
                            )}
                        </Box>
                    </label>
                    {imagePreview && !isLoading && (
                        <IconButton
                            onClick={() => setZoomed(true)}
                            aria-label="Zoom image"
                            color="primary"
                            sx={{ position: "absolute", top: "-25px", right: "-25px" }}
                        >
                            <ZoomInIcon sx={{ fontSize: "30px" }} />
                        </IconButton>
                    )}
                </Grid>


                <Dialog open={isZoomed} onClose={() => setZoomed(false)} maxWidth="md">
                    <DialogContent style={{ textAlign: 'center', padding: '0' }}>
                        <img
                            src={imagePreview}
                            alt="Zoomed crop image"
                            style={{ maxWidth: '100%', height: 'auto' }}
                        />
                    </DialogContent>
                </Dialog>


                <Grid item xs={12} mt={2}>
                    <TextField
                        label="Average Crop Heads per Square Meter"
                        variant="outlined"
                        disabled={isLoading}
                        value={farm.currentSimulationRecord.cropData.headsPerM2}
                        onChange={(e) =>
                            updateFarm(farm.id, {
                                ...farm,
                                currentSimulationRecord: {
                                    ...farm.currentSimulationRecord,
                                    cropData: {
                                        ...farm.currentSimulationRecord.cropData,
                                        headsPerM2: Number(e.target.value),
                                    },
                                },
                            })
                        }
                        type="number"
                        InputProps={{
                            endAdornment: (
                                <InputAdornment position="end">
                                    <Typography variant="body2">m²</Typography>
                                </InputAdornment>
                            ),
                        }}
                        fullWidth
                    />
                </Grid>
            </Grid>
        </Grid>
    )
};

export default CropHeadInput;
